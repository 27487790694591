body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiStepLabel-label {
  color:greenyellow;
  font-size:18px !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color:rgba(0,0,0,.87);
}



